import React from 'react'

import './about.scss'

const About = () => {
    return (
        <div id="about" className="my-3">
            <h1 className="medium mb-1">Managed VMWare Server Hosting</h1>
            <p className="mb-1">
            We offer a range of fully managed VMWare dedicated server packages from single standalone VMWare ESX hosts to massive clustered and redundant multi-host vSphere cloud environments. You’ll have full root access to your own private VMWare ESXi hypervisor and complete freedom to create new virtual machines and host whatever you want within the maximum limits of the hardware you choose. All our servers are provided by Dell and come with Intel Xeon processors and hardware RAID for maximum performance and reliability and any hardware failures are corrected within 4 hours. As a fully managed VMWare solution we’ll set it all up for you and you’ll have full access to our technical support 24 hours a day.
            </p>

            <div className="my-2">
                <ul className="about-list">
                    <li><strong> Bespoke Service:</strong> Our fully managed VMWare service is tailored to your exact requirements. We’ll bespoke build the entire solution for you and if you’re not happy with any part of it, we’ll build it again and again until we have it configured exactly how you want it.</li>
                    <li><strong> Performance Hardware:</strong> All servers supplied are very high end 2 or 4 socket Dell PowerEdge servers with Intel Xeon processors, ECC memory, hardware RAID, multiple power supplies, and enterprise SAS hard drives and SSD. No messing around!</li>
                    <li><strong> Private Datacentre:</strong> Our data centre is built inside a fully converted insurance spec bank vault for maximum security. We have redundant power with automatic generators, multiple lines, and redundant cooling but best of all, it’s privately owned so we have full and immediate access 24 hours a day.</li>
                    <li><strong> Massive Capacity:</strong> We have a mind-boggling capacity so we can scale your solution up to multiple redundant servers with massive arrays of disk space. Think up your dream solution and we’ll put it together for you.</li>
                    <li><strong> Migration Support:</strong> We can fully support you through any migration including planning and execution but why stop there? We can also offer completely free migration periods so you don’t get 2 bills while you move.</li>
                    <li><strong> Server Cloning:</strong> We can help you cone entire operating systems of both physical dedicated servers and virtual machines for any operating system and ensure they are correctly configured and up and running.</li>
                    <li><strong> Technical Support:</strong> Our comprehensive technical support is available 24 hours a day</li>
                    <li><strong>Prices from £199 + vat per month:</strong> Our managed services start as low as 2 sockets and 20 cores with 32gb of memory and 2x1TB SAS hard drives which is more than enough to get you started</li>
                </ul>
            </div>

            <div className="my-2">
                <h2 className="mb-1">VMWare Dedicated Servers</h2>

                <p className="mb-1">
                VMware software virtualises the hardware for motherboard, processors, memory, hard disks and video and network adapters which allows you to redistribute it to multiple server operating systems. A single dedicated server with 20 CPU cores and 64GB of memory could easily be used to run 32 virtual servers if you split the memory up into 2gb chunks. The VMWare software itself also allows you overprovision your resources so you can allocate more than you actually have available as only the physically used resource is calculated as used and all available resources are shared by all servers. The end result is a platform that lets you do more with less which saves you money and is better for the environment.
                </p>

            </div>
        </div>
    )
}

export default About
