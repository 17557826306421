import React, { useState, useContext } from 'react'
import Spinner from '../spinner/Spinner'

import './contact.scss'

import ContactContext from '../../../context/contact/contactContext'
// import OptionsContext from '../../../context/options/optionsContext'

const Contact = () => {

    const contactContext = useContext(ContactContext)
    // const optionsContext = useContext(OptionsContext)

    const { setContact, sendEmail, loading, error, emailSent } = contactContext
    // const { current } = optionsContext

    const [contacts, setContacts] = useState({
        email: '',
        phone: '',
        name: ''
    })
    
    const [alert, setAlert] = useState(false)


    const onChange = (e) => setContacts({ ...contacts, [e.target.name]: e.target.value })

    const onSubmit = (e) => {
        e.preventDefault()
        if (contacts.email === '' || contacts.name === '') {
            setAlert(true)
            setTimeout(() => {
                setAlert(false)
            }, 3000)
        } else {
            setContact(contacts)
            setContacts({ email: '', phone: '', name: '' })
            if(window.innerWidth < 500) { 
                sendEmail(contacts, null)
            } else {
                // sendEmail(contacts, current)
                sendEmail(contacts, null)
            }       
        }
    }

    const message = (emailSent, error, loading) => {

        if(!emailSent && !error && !loading) {
            return ( <i className="fas fa-envelope"></i> )
        }

        if (loading) {
            return ( <Spinner /> )
        }

        if (error) {
            return (
                <h4>Oops! Something went wrong!</h4>
            )
        }

        if(emailSent.sent === true) {
            return (
                <h4>Your Submission Has Been Sent!</h4>
            )
        } else if (emailSent.sent === false) {
            return (
                <h4>There was a problem with sending your Submission!</h4>
            )
        }
    }
        return (
            <div className='py-1'>
                <h2 className="price-text">VMWare server hosting prices start from £199+vat per month</h2>
                <p className={alert ? "alert alert-error" : 'alert'}>{alert ? 'Please fill in the required fields*' : 'Please contact us for full quote'}</p>
                <div className="form-section">
                    <form onSubmit={onSubmit}>
                        <input
                            className={alert && !contacts.name ? "input-error" : ''}  
                            type="text" 
                            name="name" 
                            placeholder="Name*" 
                            value={contacts.name} 
                            onChange={onChange}        
                        />
                        <input
                            className={alert && !contacts.email ? "input-error" : ''} 
                            type="email" 
                            name="email" 
                            placeholder="Email*" 
                            value={contacts.email} 
                            onChange={onChange}        
                        />
                        <input 
                            type="text" 
                            name="phone" 
                            placeholder="Phone" 
                            value={contacts.phone} 
                            onChange={onChange}        
                        />
                        <div>
                            <input type="submit" value='Get A Quote' className="btn btn-primary btn-block"/>
                        </div>
                    </form>
                    <div className="form-image">
                    { message(emailSent, error, loading) }
                    </div>
                </div>
            </div>

        )
}

export default Contact
