import React from 'react'

import './contact-us.scss'

const ContactUs = () => {
    return (
        <div id="contact" className="py-2">
            <h2>Contact Us</h2>
            <div className="contact-container my-1">
                <div className="contact-left">
                    <p className="my-1">If you have any questions about any of our services please contact us.</p>
                    <p>Phone: <a href="tel:+448453889891">0845 388 9891</a></p>
                    <p>Local: <a href="tel:+441202 375 079">01202 375 079</a></p>
                    <p className="my-1">Email: <a href="mailto:info@darknebulae.net">info@darknebulae.net</a></p>
                    <p>
                        Dark Nebulae LTD <br/>
                        106 Old Christchurch Road <br/>
                        Bournemouth <br/>
                        Dorset  <br/>
                        BH1 1LR 
                    </p>
                </div> 
                <div className="contact-right">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2525.8968185522235!2d-1.877076084292104!3d50.72185337951379!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4873a1c37940df2d%3A0x1595bd64685c285!2s106%20Old%20Christchurch%20Rd%2C%20Bournemouth%20BH1%201LR!5e0!3m2!1sen!2suk!4v1588668156583!5m2!1sen!2suk" style={{border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0" title="map"></iframe>    
                </div> 
            </div>    
        </div>
    )
}

export default ContactUs
