import React from 'react'
import Slider from 'react-slick'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './carousel.scss'

import slide1 from '../../../assets/dell-vmware.jpg'
import slide2 from '../../../assets/vmware-hosting.jpg'
import slide3 from '../../../assets/vmware-options.jpg'

const Carousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        cssEase: "linear"
        
    };

    return (
        <div className="carousel">
            <Slider {...settings}>
                <div>
                    <img className="slide-img" src={slide1} alt="slide-img"/>
                </div>
                <div>
                    <img className="slide-img" src={slide2} alt="slide-img"/>
                </div>
                <div>
                    <img className="slide-img" src={slide3} alt="slide-img"/>
                </div>
            </Slider>
        </div>
    )
}

export default Carousel;
