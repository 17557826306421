import React, { Fragment, useEffect, useState } from 'react'
import { animateScroll as scroll } from 'react-scroll';

import './scrollToTop.scss'

const ScrollToTop = () => {

    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset)
    const [visible, setVisible] = useState(true)

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    }, [prevScrollPos])

    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        const isVisible = prevScrollPos < 100;

        setPrevScrollPos(currentScrollPos);
        setVisible(isVisible);
    };

    const scrollToTop = () => {
        scroll.scrollToTop()
    }

    return (
        <Fragment>
            { !visible ? 
            <div className="scrollToTop" onClick={scrollToTop}><span><i className="fas fa-chevron-up"></i></span></div>
            : ''
         }
        </Fragment>

        
    )
}

export default ScrollToTop
