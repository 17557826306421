import React from 'react';
import './App.css';

import Header from './components/layout/header/Header'
import Carousel from './components/layout/carousel/Carousel'
import About from './components/layout/about/About'
import Contact from './components/layout/contact/Contact'
import ContactUs from './components/layout/contact-us/ContactUs'
import ScrollToTop from './components/layout/scrollToTop/ScrollToTop'
import Footer from './components/layout/footer/Footer'

import ContactState from './context/contact/ContactState'

function App() {
  return (
      <ContactState>
        <div className="app">
          <Header />
          <div className="header-gap"></div>
          <Carousel />
          <div className="container">
            <About />
            <Contact />
            <ContactUs />
          </div>
          <Footer />
          <ScrollToTop />   
        </div>
      </ContactState>
  );
}

export default App;
