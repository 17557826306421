import React from 'react'
import { Link } from 'react-scroll';

import './header.scss'

const Header = ({ title }) => {

    return (
        <header>
            <nav className="navbar">
                <h1>
                    {title}
                </h1>
                <ul>
                    <li>
                        <Link to="about" spy={true} smooth={true} duration={500} offset={-68.8}>Services</Link>
                    </li>
                    {/* <li>
                    <Link to="options" spy={true} smooth={true} duration={500} offset={-68.8}>Quote</Link>
                    </li> */}
                    <li>
                    <Link to="contact" spy={true} smooth={true} duration={500} offset={-68.8}>Contact</Link>
                    </li>
                </ul>
            </nav>
            <div className="gradient-hr"></div>
        </header>
    )
}

Header.defaultProps = {
    title: 'VMWares'
}

export default Header