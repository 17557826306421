import React, { useReducer } from 'react'
import axios from 'axios'
import ContactContext from './contactContext'
import contactReducer from './contactReducer'
import {
    SET_CONTACT,
    CLEAR_CONTACT,
    EMAIL_SUCCESS,
    SET_LOADING,
    EMAIL_ERROR
} from '../types'

const ContactState = (props) => {
    const initialState = {
        contact: null,
        emailSent: null,
        loading: false,
        error: false
    }

    const [state, dispatch] = useReducer(contactReducer, initialState)

    const setContact = (contact) => {
        dispatch({
            type: SET_CONTACT,
            payload: contact
        })
    }

    // Clear Contacts
    const clearContact = () => {
        dispatch({
            type: CLEAR_CONTACT
        })
    }

    const sendEmail = async (contact, options) => {
        setLoading(true)
        let emailData;

        if (options !== null) {
            emailData = { ...contact, ...options }
        } else {
            emailData = { ...contact }
        } 

        const config = {
            'Content-Type': 'application/json'
        }
        setTimeout( async () => {

            try {
                const res = await axios.post('/email.php', emailData, config);
    
                dispatch({
                    type: EMAIL_SUCCESS,
                    payload: res.data
                })
                setLoading(false)
            } catch (err) {
                setLoading(false)
                setError(true)
                setTimeout(() => {
                    setError(false)
                }, 3000)
            }
        }, 1000)
    }

    const setLoading = (loading) => dispatch({ type: SET_LOADING, payload: loading })

    const setError = (error) => dispatch({ type: EMAIL_ERROR, payload: error })

    return (
        <ContactContext.Provider
         value={{
             contact: state.contact,
             emailSent: state.emailSent,
             loading: state.loading,
             error: state.error,
             clearContact,
             setContact,
             sendEmail
         }}>
            { props.children }
        </ContactContext.Provider>
    )
}

export default ContactState