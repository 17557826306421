import React from 'react'

import './footer.scss'

const Footer = () => {
    
    return (
        <footer>
            <div className="gradient-hr"></div>
            <div className="footer-content">
                <div className="copy-right">
                    <p>
                        Powered by <a href="https://darknebulae.net/" target="_blank" rel="noopener noreferrer">Dark Nebulae LTD</a> © 2020 - The use of any of our services indicates an agreement and full understanding of our <a href="https://darknebulae.net/terms-and-conditions.html" target="_blank" rel="noopener noreferrer">Terms & Conditions</a> and <a href="https://darknebulae.net/privacy-policy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
