import { SET_CONTACT, CLEAR_CONTACT, SET_LOADING, EMAIL_ERROR, EMAIL_SUCCESS } from '../types'

export default (state, action) => {
    switch(action.type) {
        case SET_CONTACT:
            return {
                ...state,
                contact: action.payload
            } 
        case CLEAR_CONTACT:
            return {
                ...state,
                contact: null
            }
        case SET_LOADING: 
         return {
            ...state,
            loading: action.payload
        }
        case EMAIL_SUCCESS:
            return {
                ...state,
                emailSent: action.payload
            }
        case EMAIL_ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}